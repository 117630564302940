@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&family=Unbounded:wght@200..900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    font-family: "Rubik", sans-serif;
}

.custom-font {
    font-family: "Arial", sans-serif;
    font-size: 55px !important;
}

@media screen and (max-width: 1024px) {
    .custom-font {
        font-size: 38px !important;
        line-height: 50px !important;
    } 
}