/**
* Body smooth scroll behavior
*/
html,
body {
    scroll-behavior: smooth;
}
/*
* Hero section background image
*/
.hero-section-cover {
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0)), url('../../public/assets/images/jpg/hero-section-background-cover-4.jpg');
}

.sub-hero-section-cover {
  background-position: bottom;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../public/assets/images/jpg/hero-section-background-cover-4.jpg');
}

.project-section-slide-animation {
  animation-name: project_section_slide_animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes project_section_slide_animation {
  0% {
    opacity: 0.5;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.swiper-pagination-bullet-active {
  background: #00BD3A !important;
}

.who-we-are-list::marker {
  color: #00BD3A;
}

.newsletter-item-card {
  width: 400px;
}

@media screen and (max-width: 768px) {
  .newsletter-item-card {
    width: 100%;
  }
}